const createInnerProjection = (names, props) => {
  if (names.length) {
    return names.map((name, index) => {
      return (
        `_type == "${name}" => {
          ${props}
        }${index !== names.length - 1 ? ',' : ''}`
      )
    })
  }

  return ''
};

module.exports = { createInnerProjection };
