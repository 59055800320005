import React, { useEffect, useState } from 'react'
import StandalonePage from '../standalone-page';
import client from "@src/api/sanity/client";
import useShopifyProducts from '@src/hooks/useShopifyProducts';
import ProductView from '../product-view';
import BundleProductView from '../bundle-product-view';
import CollectionsPage from '../collection-view';
import BlogIndex from '../blog-list';
import ResourcesIndex from '../resources-list';
import BlogView from '../blog-view';
import { documentByIdQuery, getRelatedPosts, getSimpleLandingPageDetails } from './helpers';
import {ReactComponent as Spinner} from '@svgs/animated-spinner.svg'
import ResourceView from '../resource-view';

const PreviewTemplate = ({ pageContext, location }) => {
  const [document, setDocument] = useState({});
  const { products: shopifyProducts, getShopifyProduct } = useShopifyProducts();

  const urlParams = new URLSearchParams(location?.search);
  const documentId = urlParams.get('id');
  const documentType = urlParams.get('type');

  const getDocument = async () => {
    const previewDocument = await client.fetch(documentByIdQuery(documentId, documentType));

    if (previewDocument) {
      setDocument(previewDocument)
    }
  }

  useEffect(() => {
    getDocument();
  }, [])

  return (
    <Preview pageContext={pageContext} location={location} document={document} products={shopifyProducts} getShopifyProduct={getShopifyProduct} />
  )
}

export default PreviewTemplate

const Preview = ({ pageContext, document, location, products, getShopifyProduct }) => {
  const [loading, setLoading] = useState(true);
  const [hasSupportedType, setHasSupportedType] = useState(true);
  
  const ProductTemplate = document?.isBundle ? BundleProductView : ProductView;
  const CategoryTemplate = document?.group === 'blog' ? BlogIndex : ResourcesIndex;

  const { 
    Component: SimpleLandingPageComponent, 
    props: simpleLandingPageProps 
  } = getSimpleLandingPageDetails(
    document, 
    products, 
    {
      CollectionsPage, 
      BlogIndex, 
      ResourcesIndex, 
      StandalonePage, 
      Empty: React.Fragment
    }
  );

  const documentTypes = {
    landingPage: <StandalonePage location={location} pageContext={{
      ...pageContext,
      documentId: document?._id,
      page: document,
      announcementBar: pageContext?.siteSettings,
      hasTopPadding: document?.slug?.current !== 'home',
    }} />,
    shopifyProduct: <ProductTemplate location={location} pageContext={{
      ...pageContext,
      product: getShopifyProduct(document),
      productEntry: document,
      productsShopify: products,
      servicesPoliciesInfo: document?.servicesPoliciesInfo,
    }} />,
    productGroup: <CollectionsPage location={location} pageContext={{
      ...pageContext,
      data: {
        documentId: `/(drafts.|)${document?._id?.replace('drafts.', '')}/`,
        pageEntry: document,
        collection: document?.productGroups?.nodes?.find(productGroup => productGroup._id === document._id),
        productGroups: document?.productGroups,
        productEntries: document?.productsListing,
        productsShopify: products,
      },
    }} />,
    category: <CategoryTemplate location={location} pageContext={{
      ...pageContext,
      hasTopPadding: false,
      activeCategorySlug: document?.slug?.current,
      pageEntry: document?.pageEntry,
      categories: document?.categories,
      entries: document?.entries?.filter(entry => entry?.categories?.map(document => document?._id).includes(document?._id)),
      categoryDocumentIds: document?.group !== 'blog' ? document?.entries?.map(entry => entry?._id) : null
    }} />,
    blogPost: <BlogView location={location} pageContext={{
      ...pageContext,
      entry: document,
      relatedBlogs: getRelatedPosts(document?.posts)
    }} />,
    resourcePost: <ResourceView location={location} pageContext={{
      ...pageContext,
      entry: document,
      relatedResources: getRelatedPosts(document?.posts)
    }} />,
    simpleLandingPage: <SimpleLandingPageComponent location={location} pageContext={{
      ...pageContext,
      ...simpleLandingPageProps
    }} />
  };

  useEffect(() => {
    if (Object.entries(document).length > 0) {
      if (documentTypes[document?._type]) {
        setHasSupportedType(true);
      } else {
        setHasSupportedType(false)
      }
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [document]);

  return loading 
    ? <Spinner className={'max-w-full max-h-[100vh]'} /> 
    : hasSupportedType
      ? documentTypes[document?._type] 
      : (
        <div className='pt-20 container text-xl'>
          This Document type is not supported
        </div>
      )
}